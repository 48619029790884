.container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.section1 {
    color: white;
    padding: 0 20px;
    flex: 0 0 46px;
    background-color: #333333;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.section2-container {
    flex: 1;
    overflow-y: auto;
}

.section2 {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section2>div:last-of-type {
    margin-bottom: 25px;
}

.PDFPage {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    margin-top: 25px;
}